import React from "react"
import Layout from "../../components/layout"
import tulsa1 from "../../images/tulsa-1.jpg"
import tulsa2 from "../../images/tulsa-2.jpg"
import tulsa34 from "../../images/tulsa-3-4.jpg"
import tulsa5 from "../../images/tulsa-5.jpg"
import HearFromYouForm from "../../components/forms/hearfromyou"

//SEO
let title = `TULSA Procedure | Case Study`
let desc = `TULSA Procedure Case Study for RadNet Prostate.`

    const CaseStudy = () => {
        return (
            <Layout page={title} desc={desc}>
                <div className="inside-page tulsa mb5 mb7-l">
                    <div class="container-inner flex-l flex-wrap clearfix">
                        <div class="w-100-xs w-100-m w-100-l mb4">
                            <h1 class="center"><strong>Case Study</strong></h1>
                            <h3 class="center"><strong>Presented by Martin Cohen, MD<br/>and Robert Princenthal, MD Co-Directors of RadNet’s Prostate Program</strong></h3>
                            <p class="center"><strong>PATIENT HISTORY: </strong>65 year old male with elevated PSA, 7.6</p>
                        </div>
                    </div>
                    <div class="container-inner flex-l flex-wrap clearfix">
                        <div class="w-100-xs w-100-m w-50-l mb4 grow">
                            <img src={tulsa1} alt="Prostate TULSA Procedure Case Study" />
                        </div>
                        <div class="w-100-xs w-100-m w-50-l mb4">
                            <div class="ma4">
                                <p>
                                    Multiparametric MRI demonstrated a 1.0 CM hypointense nodule left apex <strong>(Fig. 1 top left)</strong>. Low signal on the ADC map-restricted diffusion (bottom left). Increased perfusion <strong>(Fig. 1 top &amp; bottom right)</strong>.
                                </p>
                                <p>
                                    Biopsy performed by the urologist showed Gleason 3+4 left apex 30% core. Additional Gleason 3+3 carcinoma left mid gland and left base 15% core and left apex 5% core.
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr class="hr-space mb4"/>
                    <div class="container-inner flex-l flex-wrap clearfix mb4">
                        <div class="w-100-xs w-100-m w-50-l mb4 grow">
                            <img src={tulsa2} alt="Prostate TULSA Procedure Case Study" />
                        </div>
                        <div class="w-100-xs w-100-m w-50-l mb4">
                            <div class="ma4">
                                <p>
                                    The patient elected to undergo whole gland TULSA ablation as an alternative to radiation therapy or radical prostatectomy. A disposable rotating urethral ultrasound device with 10 treating elements is placed by the urologist. A rectal cooling device is also inserted to protect the rectum from heating <strong>(fig.2 – sagittal)</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr class="hr-space mb4"/>
                    <div class="container-inner flex-l flex-wrap clearfix mb4">
                        <div class="w-100-xs w-100-m w-50-l mb4 grow">
                            <img src={tulsa34} alt="Prostate TULSA Procedure Case Study" />
                            <img src={tulsa5} alt="Prostate TULSA Procedure Case Study" />
                        </div>
                        <div class="w-100-xs w-100-m w-50-l mb4">
                            <div class="ma4">
                                <p>
                                    The treatment plan is outlined by the radiologist to preserve the neurovascular bundles and urethral sphincter. During the TULSA procedure the radiologist continuously monitors gland temperature using MRI thermometry. Optimal temperature for irreversible tissue ablation is 55°C (yellow).
                                </p>
                                <ul class="no-bullets">
                                    <li><strong>Fig. 3</strong> shows initial treatment in progress (axial)</li>
                                    <li><strong>Fig. 4</strong> following the entire gland treatment (lower midgland level)</li>
                                    <li><strong>Fig. 5</strong> shows lack of perfusion in the ablated zone following intravenous contrast enhancement.</li>
                                </ul>
                                <p>
                                    Following recovery from general anesthesia, the patient is sent home with a Foley catheter for 7 to 10 days.
                                </p>
                            </div>
                        </div>
                    </div>
                    <HearFromYouForm />
                </div>
            </Layout>
        )
    }
    export default CaseStudy